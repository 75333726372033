import React from 'react';
//, {useState} removed
import '../App.css';
import {Link} from "react-router-dom";
import { useAuth } from "../context/AuthContext";

// must remember import component in App.js
export const About = () => {
    const { user, logout } = useAuth();
    return (
        <div className="morphpagecontent">
            {/* // ----------------------------------------------------------------------- */}
            {/* // Once this page is ready I will put this nav in Navbar.js and export it as a component in App.js */}
            {/* <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark" > */}
            <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "#2d2c32", color:"#FFF" }} >
                <div className="container-fluid" >
                <Link className="navbar-brand" to="/" >Karuta Market</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
                            <li className="nav-item">
                                <Link className="nav-link"  href="#" to="/">Frames</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" href="#" to="/Morphs">Morphs</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" href="#"  to="/About">About</Link>
                            </li>
                        </ul>
                        {user ? (
                            <>
                            <form className="d-flex">
                                <Link className="btn btn-outline-success" href="#"  to="/profile" type="submit">Profile</Link>
                            </form>
                            <form className="d-flex">
                                <button className="btn btn-outline-success" href="#"  to="/profile" type="submit" onClick={logout}>Logout</button>
                            </form>
                            
                            </>
                        ) : (
                            <form className="d-flex">
                                <Link className="btn btn-outline-success" href="#"  to="/Login" type="submit">Login</Link>
                            </form>
                        )}
                        
                    </div>
                </div>
            </nav>
            {/* // ----------------------------------------------------------------------- */}

            <main id ="infiniteScrollContainer">
                <div className="container mt-5">
                    <div className="card p-4 shadow-sm border-0" style={{ backgroundColor: "#2b2d31", color: "#ffffff" }}>
                        <h1 className="text-center mb-4">About Karuta Market</h1>
                        <p className="lead">
                            Karuta Market is a website designed for players of the <strong>Karuta</strong> Discord game. It helps users <strong>search, view, and track</strong> item prices efficiently.
                        </p>

                        <h3 className="mt-4">Frames</h3>
                        <p>
                            The <strong>Frames</strong> page allows users to easily find information about different frames, including their <strong>prices, availability, and details</strong>.
                        </p>

                        <h3 className="mt-4">Morphs</h3>
                        <p>
                            The <strong>Morphs</strong> gallery will showcase different morphs and how they look when applied to various frames, making it easier to visualize their designs.
                        </p>

                        <h3 className="mt-4">Future Plans</h3>
                        <p>
                            More features will be added over time to enhance the experience for Karuta players.
                        </p>

                        <div className="text-center mt-4">
                            <a href="/" className="btn btn-primary">Back to Home</a>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
};
 
