import * as React from "react";
import './App.css';
//import Axios from "axios";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { FrameInfo } from './pages/FrameInfo';
import { About } from './pages/About';
import { Navbar } from "./Navbar";
import { Api } from "./pages/Api";
import { Login } from "./pages/Login";
import { Profile } from "./pages/Profile";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import { Morphs } from "./pages/Morphs";
import { Test } from "./pages/Test";
import NotFound from "./components/NotFound";
import { AuthProvider } from './context/AuthContext';  // Import AuthProvider
import AuthRoute from "./AuthRoute";

function App() {
  //defualtoptions has lots of configurations
  const client = new QueryClient();
  return (
    <div className="App">
      <QueryClientProvider client={client}>
       <AuthProvider>
          <Router>
            <div id="content">
              <header>
                <div className="title">

                </div>
              </header>
              {/* THIS NAVBAR BELOW IS A COMPONENT. SO THE NAVBAR IS GETTING IMPORTED FROM NAVBAR.JS */}
              <Navbar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/morphs" element={<Morphs />} />
                <Route path="/frameinfo" element={<FrameInfo />} />
                <Route path="/about" element={<About />} />
                <Route path="/api" element={<Api />} />
                <Route path="/login" element={<Login />} />
                <Route path="/teststuff" element={<Test />} />

                <Route element={<AuthRoute />}>
                  <Route path="/profile" element={<Profile />} />
                </Route>
                
                <Route path="*" element={<NotFound />} />
              </Routes>
              {/* <div>footer</div> */}
            </div>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
