import React, { useState, useEffect } from 'react';
// will be used once I add content
// import { useQuery } from '@tanstack/react-query';
// import { formatName } from '../formatName';
// import MyImageComponent from '../getImagePath';
// import InfiniteScroll from 'react-infinite-scroll-component';
import '../App.css';
import {Link} from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export const Morphs = () => {
    const { user, logout } = useAuth();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [rightSideBarVisible, setRightSidebarVisible] = useState(true);
    const [filters, setFilters] = useState([]);  // filter state
    const [isMobile, setIsMobile] = useState(() => window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleFilterSidebarToggle = () => {
        setSidebarVisible(!sidebarVisible);
    };
    const handlerightSidebarToggle = () => {
        setRightSidebarVisible(!rightSideBarVisible);
    };

    const [visibleItems, setVisibleItems] = useState(20);
    const handleFilterChange = (event) => {
        const { value, checked } = event.target;
        // console.log(`Filter changed: ${value}, Checked: ${checked}`);
        setFilters(prevFilters => 
            checked ? [...prevFilters, value] : prevFilters.filter(filter => filter !== value)
        );
        setVisibleItems(50); 
    };
    console.log(filters, visibleItems);
    
    return (    
        <div className='morphpagecontent'>
            {/* // ----------------------------------------------------------------------- */}
            {/* // Once this page is ready I will put this nav in Navbar.js and export it as a component in App.js */}
            {/* <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark" > */}
            <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "#2d2c32", color:"#FFF" }} >
                <div className="container-fluid" >
                <Link className="navbar-brand" to="/" >Karuta Market</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
                            <li className="nav-item">
                                <Link className="nav-link"  href="#" to="/">Frames</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" href="#" to="/Morphs">Morphs</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" href="#"  to="/About">About</Link>
                            </li>
                        </ul>
                        {user ? (
                            <>
                            <form className="d-flex">
                                <Link className="btn btn-outline-success" href="#"  to="/profile" type="submit">Profile</Link>
                            </form>
                            <form className="d-flex">
                                <button className="btn btn-outline-success" href="#"  to="/profile" type="submit" onClick={logout}>Logout</button>
                            </form>
                            
                            </>
                        ) : (
                            <form className="d-flex">
                                <Link className="btn btn-outline-success" href="#"  to="/Login" type="submit">Login</Link>
                            </form>
                        )}
                        
                    </div>
                </div>
            </nav>
            {/* // ----------------------------------------------------------------------- */}
            <main id="infiniteScrollContainer">
            <button 
                className="btn btn-secondary " 
                onClick={handleFilterSidebarToggle}
                style={{
                    display: (!isMobile && !sidebarVisible) ? 'none' : 'block',
                    position: 'fixed',
                    top: '70px',
                    left: '10px',
                    background: '#2b2d31',
                    zIndex: 1000
                }}>
                <i className="fa-solid fa-filter"></i>
            </button>
                <button className={`upload-button btn btn-secondary ${rightSideBarVisible ?  "d-none": "d-block"}`} onClick={handlerightSidebarToggle}
                    style={{
                        background: '#2b2d31',
                        position: 'fixed', 
                        top: '70px',
                        right: '10px',
                        zIndex: 1000 
                    }}>
                    <i className="fa-solid fa-upload"></i>
                </button>
                <div className="row">
                    {/* Left Sidebar - Filters */}
                    <aside className={`col-12 col-md-3 sidebar ${sidebarVisible ? 'd-block' : 'd-none d-md-block'} p-3 rounded`}
                        style={{ 
                            maxWidth: sidebarVisible ? '100%': '215px',
                            backgroundColor: "#2b2d31",
                            transition: 'max-width 0.3 ease',
                            
                            }}
                    >
                        <h3 className='filter-title'>Filter</h3>
                        <form className='filterForm'>
                            <label>
                            <div className='filterItemWrapper'>
                            <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="transparent" name="transparent" value="transparent" />
                            <label htmlFor="transparent"> Transparent</label>
                            </div>
                            </label>

                            <label htmlFor="special">
                            <div className='filterItemWrapper'>
                            <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="special" name="special" value="special" />
                            <label htmlFor="special"> Special</label>
                            </div>
                            </label>

                            <label htmlFor="carousel">
                            <div className='filterItemWrapper'>
                            <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="carousel" name="carousel" value="carousel" />
                            <label htmlFor="carousel"> Carousel</label>
                            </div>
                            </label>

                            <label htmlFor="event">
                            <div className='filterItemWrapper'>
                            <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="event" name="event" value="event" />
                            <label htmlFor="event"> Event</label>
                            </div>
                            </label>

                            <label htmlFor="bit">
                            <div className='filterItemWrapper'>
                            <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="bit" name="bit" value="bit" />
                            <label htmlFor="bit"> Bit</label>
                            </div>
                            </label>

                            <label htmlFor="dojo">
                            <div className='filterItemWrapper'>
                            <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="dojo" name="dojo" value="dojo" />
                            <label htmlFor="dojo"> Dojo</label>
                            </div>
                            </label>

                            <label htmlFor="monthly">
                            <div className='filterItemWrapper'>
                            <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="monthly" name="monthly" value="monthly" />
                            <label htmlFor="monthly"> Monthly</label>
                            </div>
                            </label>

                        </form>
                    </aside>
                    
                    <div className='col-12 col-md-6 d-flex align-items-center' style={{flexGrow: 1}}>
                        <p>This will have images like the home page in an infinite scroll container but they will be morphs.</p>
                    </div>
                    {/* <InfiniteScroll
                        dataLength={visibleItems}
                        next={fetchMoreData}
                        hasMore={visibleItems < filteredData.length}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="infiniteScrollContainer"
                        className='scrollableArea'
                    >
                        {filteredData.slice(0, visibleItems).map(([name, { low, high }]) => (
                            <div key={name} className='scroll-item'>
                                <h2 id='framename'>{formatName(name)}</h2>
                                <div className="image-container">
                                    <MyImageComponent
                                    itemName={name} 
                                    />
                                </div>
                                <div className='prices-text'>
                                    <div className='low-price'>Low: 🎟️{low}</div>
                                    <div className='high-price'>High: 🎟️{high}</div>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll> */}
                    {/* <aside className={`col-12 col-md-3 p-3 rounded user-sidebar ${rightSideBarVisible ? "d-block" : "d-block"}`}
                            style={{ backgroundColor: "#2b2d31", position: "relative", maxWidth: rightSideBarVisible ? '3000px' : '0px' }}> */}
                            
                            {/* Close Button */}
                            {/* <button className="btn-close" onClick={handlerightSidebarToggle} 
                                style={{ position: "absolute", top: "10px", right: "10px" }}></button>
                            
                            <h3>Upload Morph</h3>
                            <form>
                                <input type="file" className="form-control" />
                                <button type="submit" className="btn btn-primary mt-2">Upload</button>
                            </form>
                        </aside> */}
                    {user ? (
                        <aside className={`col-12 col-md-3 p-3 rounded user-sidebar ${rightSideBarVisible ? "d-block" : "d-none"}`}
                            style={{ 
                                backgroundColor: "#2b2d31",
                                position: "relative",
                                maxWidth: rightSideBarVisible ? '300px' : '300px' }}>
                            
                            {/* Close Button */}
                            <button className="btn-close" onClick={handlerightSidebarToggle} 
                                style={{ position: "absolute", top: "10px", right: "10px" }}></button>
                            
                            <h3>Upload Morph</h3>
                            <form>
                                <input type="file" className="form-control" />
                                <button type="submit" className="btn btn-primary mt-2">Upload</button>
                            </form>
                        </aside>
                    ): null}
                </div>
            </main>
        </div>
    );
    

}
