import React, { useEffect, useState } from 'react';
import '../App.css';
import {Link} from "react-router-dom";
import { useAuth } from '../context/AuthContext.jsx';
import { useNavigate, useLocation } from 'react-router-dom';

export const Login = () => {

    const [username, setUsername] = useState('');
    const { isLoggedIn, login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || "/profile";

    useEffect(() => {
        if (isLoggedIn) {
            navigate(from, {replace:true})
        }

    }, [isLoggedIn, navigate, from]);

    const handleLogin = (e) => {
        e.preventDefault(); 

        if (username) {
            login(username)
            navigate('/profile');  // Redirect to profile page after login
        }
    };

    return (    
        <div className='container-fluid vh-100 vw-100 m-0 p-0'>
            {/* // ----------------------------------------------------------------------- */}
            {/* // Once this page is ready I will put this nav in Navbar.js and export it as a component in App.js */}
            {/* <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark" > */}
            <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "#2d2c32", color:"#FFF" }} >
                <div className="container-fluid" >
                <Link className="navbar-brand" to="/" >Karuta Market</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Frames</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/Morphs">Morphs</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active"  to="/About">About</Link>
                            </li>
                        </ul>
                        
                    </div>
                </div>
            </nav>
            {/* // ----------------------------------------------------------------------- */}

            <main className="vh-100 d-flex justify-content-center align-items-center">
                <div className="card p-4" style={{backgroundColor: "#2b2d31",color:"#FFF" }}>
                    <h2 className="card-title text-center">Login</h2>
                    <div className="input-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <button className="btn btn-outline-success" onClick={handleLogin}>
                        Login
                    </button>
                    </div>
                </div>
            </main>
        </div>
    );
    

}
