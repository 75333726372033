import React from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

const AuthRoute = () => {
  const { user } = useAuth();

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default AuthRoute;
