import React from 'react';
import '../App.css';
import {Link} from "react-router-dom";
import { useAuth } from '../context/AuthContext.jsx';


export const Profile = () => {
  const { user, logout } = useAuth();

  return (
    <div className='container-fluid vh-100 vw-100 m-0 p-0'>
		{/* // ----------------------------------------------------------------------- */}
		{/* // Once this page is ready I will put this nav in Navbar.js and export it as a component in App.js */}
		{/* <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark" > */}
		<nav className="navbar navbar-expand-lg" style={{ backgroundColor: "#2d2c32", color:"#FFF" }} >
			<div className="container-fluid" >
			<Link className="navbar-brand" to="/" >Karuta Market</Link>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav me-auto mb-2 mb-lg-0" >
						<li className="nav-item">
							<Link className="nav-link"  href="#" to="/">Frames</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" href="#" to="/Morphs">Morphs</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link active" href="#"  to="/About">About</Link>
						</li>
					</ul>
					{user ? (
						<>
						<form className="d-flex">
							<Link className="btn btn-outline-success" href="#"  to="/profile" type="submit">Profile</Link>
						</form>
						<form className="d-flex">
							<button className="btn btn-outline-success" href="#"  to="/profile" type="submit" onClick={logout}>Logout</button>
						</form>
						
						</>
					) : (
						<form className="d-flex">
							<Link className="btn btn-outline-success" href="#"  to="/Login" type="submit">Login</Link>
						</form>
					)}
					
				</div>
			</div>
		</nav>
		{/* // ----------------------------------------------------------------------- */}

      <main className="vh-100 d-flex justify-content-center align-items-center">
        <div>
          <h2>Welcome, {user}!</h2>
          <p>This is your profile page.</p>
        </div>
      </main>
  </div>
  );
};
