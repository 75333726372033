import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { formatName } from '../formatName';
import MyImageComponent from '../getImagePath';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Link} from "react-router-dom";
import { useAuth } from "../context/AuthContext";

// import ModalComponent from '../Modal';
import '../App.css';

export const Test = () => {
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ['Frames'],
        queryFn: async () => {
            try {
                const response = await fetch('https://raw.githubusercontent.com/toottootx/BGBerry-JSONs/main/sortedFrames.json');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return await response.json();
            } catch (error) {
                throw error; // rethrow the error to be caught by React Query's error handling
            }
        }
    });

    const { user, logout } = useAuth();
    const [visibleItems, setVisibleItems] = useState(20);  // initial number of items
    const [filters, setFilters] = useState([]);  // filter state
    const [minPrice, setMinPrice] = useState('');  // minimum price filter
    const [maxPrice, setMaxPrice] = useState('');  // maximum price filter
    const [searchName, setSearchName] = useState('');  // maximum price filter
    // useStates for handling the prices and toggling of bit frames
    const [bitPriceToggle, setBitPriceToggle] = useState({}); // Track toggled state per item
    const [bitPrices, setBitPrices] = useState({}); // Store bit prices per item
    const [loadingItems, setLoadingItems] = useState({}); // Track loading state per item

    const fetchMoreData = () => {
        setVisibleItems(prev => prev + 30);  // load more items
    };

    const handleFilterChange = (event) => {
        const { value, checked } = event.target;
        // console.log(`Filter changed: ${value}, Checked: ${checked}`);
        setFilters(prevFilters => 
            checked ? [...prevFilters, value] : prevFilters.filter(filter => filter !== value)
        );
        setVisibleItems(50);  // reset visible items when filter changes
    };

    const handlePriceChange = (event) => {
        const { name, value } = event.target;
        if (name === 'minPrice') {
            setMinPrice(value);
        } else {
            setMaxPrice(value);
        }
        setVisibleItems(50);  // reset visible items when filter changes
    };

    const handleSearchName = (event) => {
        setSearchName(event.target.value);
        setVisibleItems(50);  // reset visible items when filter changes
    };

    const filteredData = data && Object.entries(data).filter(([name, item]) => {
        const matchesTags = filters.length === 0 || (item.tags && filters.every(filter => item.tags.includes(filter)));
        const matchesMinPrice = minPrice === '' || item.low >= parseFloat(minPrice);
        const matchesMaxPrice = maxPrice === '' || item.high <= parseFloat(maxPrice);
        const matchesName = searchName === '' || name.toLowerCase().includes(searchName.toLowerCase());
        return matchesTags && matchesMinPrice && matchesMaxPrice && matchesName;
    });

    // console.log('Filtered Data:', filteredData);

    const [sidebarVisible, setSidebarVisible] = useState(false);

    const handleSidebarToggle = () => {
        setSidebarVisible(!sidebarVisible);
    };

    if (isLoading) return(
        <div className="fullscreen-container">
            <h1>Loading...</h1>
        </div>
    ); 
    if (isError) return(
        <div className="fullscreen-container">
            <h1>Error: {error.message}</h1>
        </div>
    )  


    // ------------------------------------------------------------------------------
    const handleBitCurrencySwitch = async (name) => {
        setLoadingItems((prev) => ({ ...prev, [name]: true }));

        try {
            const token = 'U2FsdGVkX19LBQaNR2SjhZ+W8m4udoXMDK9QydrnkKc=.YpQkBhbSrWeRPRSr';
            let preencodedname = name.replace(" frame", "")
            preencodedname = preencodedname.replaceAll("`", "")
            const encodedName = encodeURIComponent(preencodedname);
            console.log(encodedName)

            const response = await fetch(`https://api.playadev.me/?frame=${encodedName}`, {
                method: "GET",
                headers: {
                    "token": token,
                    "Content-Type": "application/json"
                },
            });
            const contentType = response.headers.get("content-type");
            if (!response.ok) {
                const errorText = contentType && contentType.includes("application/json")
                    ? JSON.stringify(await response.json())
                    : await response.text();
                throw new Error(`HTTP error! Status: ${response.status} - ${errorText}`);
            };

            const data = await response.json();
            console.log("API Response:", data);

            if (data.Success && data.Data && data.Data.frameResult.length > 0) {
                const result = data.Data.frameResult[0];
                setBitPrices((prev) => ({
                    ...prev,
                    [name]: { price: result.price }
                }));
            } else {
                console.warn("Unexpected API response format:", data);
            }
        } catch (error) {
            console.error("Failed to fetch new prices:", error);
        }

        setLoadingItems((prev) => ({ ...prev, [name]: false }));
        setBitPriceToggle((prev) => ({ ...prev, [name]: !prev[name] }));
    };

    // Function to determine if an item should show the button (checks for "bit" tag)
    const hasBitTag = (name) => {
        const item = data[name]; // Get item from JSON data
        return item && item.tags && item.tags.includes("bit");
    };

    // Updated price rendering function
    const renderPrice = (name, low, high) => {
        if (bitPriceToggle[name] && bitPrices[name]) {
            return (
                <div>
                    <div className="low-price">{bitPrices[name].price} Bits</div>

                </div>
            );
        } else {
            return (
                <div className='prices-text'>
                    <div className="low-price">Low: 🎟️{low}</div>
                    <div className="high-price">High: 🎟️{high}</div>
                </div>
            );
        }
    };


    return (
        
        <div className="container-fluid">
            {/* // ----------------------------------------------------------------------- */}
            {/* // Once this page is ready I will put this nav in Navbar.js and export it as a component in App.js */}
            {/* <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark" > */}
            <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "#2d2c32", color:"#FFF" }} >
                <div className="container-fluid" >
                <Link className="navbar-brand" to="/" >Karuta Market</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
                            <li className="nav-item">
                                <Link className="nav-link"  href="#" to="/">Frames</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" href="#" to="/Morphs">Morphs</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" href="#"  to="/About">About</Link>
                            </li>
                        </ul>
                        {user ? (
                            <>
                            <form className="d-flex">
                                <Link className="btn btn-outline-success" href="#"  to="/profile" type="submit">Profile</Link>
                            </form>
                            <form className="d-flex">
                                <button className="btn btn-outline-success" href="#"  to="/profile" type="submit" onClick={logout}>Logout</button>
                            </form>
                            
                            </>
                        ) : (
                            <form className="d-flex">
                                <Link className="btn btn-outline-success" href="#"  to="/Login" type="submit">Login</Link>
                            </form>
                        )}
                        
                    </div>
                </div>
            </nav>
            {/* // ----------------------------------------------------------------------- */}
            <div className='filter-toggle' onClick={handleSidebarToggle}>
                <i className="fa-solid fa-filter"></i>
            </div>
            <div className="row">
                <aside className={`col-12 col-md-3 sidebar ${sidebarVisible ? 'd-block' : 'd-none d-md-block'} p-3 rounded`}
                    style={{ 
                        maxWidth: sidebarVisible ? '100%': '215px',
                        backgroundColor: "#2b2d31",
                        transition: 'max-width 0.3 ease',
                        
                        }}
                >
                    <h3 className='filter-title'>Filter</h3>
                    <form className='filterForm'>
                        <input
                            type="text"
                            id="searchName"
                            name="searchName"
                            value={searchName}
                            onChange={handleSearchName}
                            placeholder="Search Frame"
                            className=' form-control mb-2'
                        />

                        <input
                            type="number"
                            id="minPrice"
                            name="minPrice"
                            value={minPrice}
                            onChange={handlePriceChange}
                            placeholder="Min Price"
                            className=' form-control mb-2'
                        />

                        <input
                            type="number"
                            id="maxPrice"
                            name="maxPrice"
                            value={maxPrice}
                            onChange={handlePriceChange}
                            placeholder="Max Price"
                            className=' form-control mb-2'
                        />

                        <label>
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="transparent" name="transparent" value="transparent" />
                        <label htmlFor="transparent"> Transparent</label>
                        </div>
                        </label>

                        <label htmlFor="special">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="special" name="special" value="special" />
                        <label htmlFor="special"> Special</label>
                        </div>
                        </label>

                        <label htmlFor="carousel">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="carousel" name="carousel" value="carousel" />
                        <label htmlFor="carousel"> Carousel</label>
                        </div>
                        </label>

                        <label htmlFor="event">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="event" name="event" value="event" />
                        <label htmlFor="event"> Event</label>
                        </div>
                        </label>

                        <label htmlFor="bit">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="bit" name="bit" value="bit" />
                        <label htmlFor="bit"> Bit</label>
                        </div>
                        </label>

                        <label htmlFor="dojo">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="dojo" name="dojo" value="dojo" />
                        <label htmlFor="dojo"> Dojo</label>
                        </div>
                        </label>

                        <label htmlFor="monthly">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="monthly" name="monthly" value="monthly" />
                        <label htmlFor="monthly"> Monthly</label>
                        </div>
                        </label>

                    </form>
                </aside>
                

                <main className="col-md-9" id="infiniteScrollContainer"
                    style={{ flexGrow: 1 }}
                >
                    <InfiniteScroll
                        dataLength={visibleItems}
                        next={fetchMoreData}
                        hasMore={visibleItems < filteredData.length}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="infiniteScrollContainer"
                        className='scrollableArea'
                    >
                            {filteredData.slice(0, visibleItems).map(([name, { low, high }]) => {
                                //console.log(`Item: ${name}, hasBitTag: ${hasBitTag(name)}`);
                                return (
                                    <div key={name} className='scroll-item'>
                                        <h2 id='framename'>{formatName(name)}</h2>
                                        <div className="image-container">
                                            <MyImageComponent
                                            itemName={name} 
                                            />
                                        </div>
                                        {renderPrice(name, low, high)}
                                        <div className='button'>
                                            {hasBitTag(name) && (
                                                <button type='button' className="btn btn-outline-secondary" onClick={() => handleBitCurrencySwitch(name)}>
                                                    {loadingItems[name] ? "Loading..." : bitPriceToggle[name] ? "Switch to Tickets" : "Switch to Bits"}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                    </InfiniteScroll>
                </main>

                <footer className=" col-12 text-center mt-2">
                    <nav className="nav justify-content-center">
                        <a className="nav-link text-white" href="/api">API</a>
                        <a className="nav-link text-white" href="https://hatsune.me/support" target="_blank" rel="noopener noreferrer">Partner Server</a>
                        <a className="nav-link text-white" href="https://guns.lol/toottoot" target="_blank" rel="noopener noreferrer">Contact</a>
                    </nav>
                </footer>
            </div>
        </div>


    );
}
