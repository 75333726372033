import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null); // data is stored here
  const [token, setToken] = useState(null); // data is stored here

useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
        token = JSON.parse(token);
        if(token.expiration < new Date().getTime())
            logout(); // Remove token if expired
        else
        {
            setIsLoggedIn(true); // Convert token existence to boolean
            setToken(token);
            setUser(token.username)
        }
    }        
}, []);

  const login = (username) => {
    const now = new Date();
    const fakeToken = { username, expiration: now.setHours(now.getHours() + 1) };
    localStorage.setItem("token", JSON.stringify(fakeToken))
    setIsLoggedIn(true);
    setUser(username);
    setToken(fakeToken)
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    setUser(null);
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, token, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access authentication context
export const useAuth = () => useContext(AuthContext);